/*************************************************************************************
 * Middlefork Material Themes
 * --------------------
 * usage:
 * in your styles.scss,
 * - use mf-mat-themes to select which material theme to use (dark or light)
 * - use @angular/material to set the theme
 *
 * ```scss
 * @use 'mf-material-themes' as mf-mat-themes;
 * @use '@angular/material' as mat;
 *
 * @include mat.all-component-themes(mf-mat-themes.$mf-dark-theme);
 * ```
 *************************************************************************************/
@use '@angular/material' as mat;
@use 'mf-colors' as mf-colors;
@include mat.core();

/* Theme Documentation
 * https://github.com/indepth-dev/community/blob/main/files/en-us/tutorials/angular/angular-material-theming-system-complete-guide.md
 */

/* custom palette docs
 https://jnpiyush.medium.com/how-to-create-custom-theme-in-angular-material-bd518818d2fc
 https://levelup.gitconnected.com/defining-your-own-theme-in-angular-material-8a4a6ffad400
 */

$mf-mat-palette-blue: (
  50: map-get(mf-colors.$mf-palette_blue, 50),
  100: map-get(mf-colors.$mf-palette_blue, 60),
  200: map-get(mf-colors.$mf-palette_blue, 70),
  300: map-get(mf-colors.$mf-palette_blue, 80),
  400: map-get(mf-colors.$mf-palette_blue, 90),
  500: map-get(mf-colors.$mf-palette_blue, 100),
  600: map-get(mf-colors.$mf-palette_blue, 100),
  700: map-get(mf-colors.$mf-palette_blue, 100),
  800: map-get(mf-colors.$mf-palette_blue, 100),
  900: map-get(mf-colors.$mf-palette_blue, 100),
  contrast: (
    50: black,
    100: black,
    200: black,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$mf-mat-palette-jump-blue: (
  50: map-get(mf-colors.$mf-palette_jump_blue, 50),
  100: map-get(mf-colors.$mf-palette_jump_blue, 60),
  200: map-get(mf-colors.$mf-palette_jump_blue, 70),
  300: map-get(mf-colors.$mf-palette_jump_blue, 80),
  400: map-get(mf-colors.$mf-palette_jump_blue, 90),
  500: map-get(mf-colors.$mf-palette_jump_blue, 90),
  600: map-get(mf-colors.$mf-palette_jump_blue, 100),
  700: map-get(mf-colors.$mf-palette_jump_blue, 100),
  800: map-get(mf-colors.$mf-palette_jump_blue, 100),
  900: map-get(mf-colors.$mf-palette_jump_blue, 100),
  contrast: (
    50: black,
    100: black,
    200: black,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$mf-mat-palette-teal: (
  50: map-get(mf-colors.$mf-palette_teal, 50),
  100: map-get(mf-colors.$mf-palette_teal, 60),
  200: map-get(mf-colors.$mf-palette_teal, 70),
  300: map-get(mf-colors.$mf-palette_teal, 80),
  400: map-get(mf-colors.$mf-palette_teal, 90),
  500: map-get(mf-colors.$mf-palette_teal, 100),
  600: map-get(mf-colors.$mf-palette_teal, 100),
  700: map-get(mf-colors.$mf-palette_teal, 100),
  800: map-get(mf-colors.$mf-palette_teal, 100),
  900: map-get(mf-colors.$mf-palette_teal, 100),
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$mf-primary: mat.define-palette($mf-mat-palette-blue);
$mf-accent: mat.define-palette($mf-mat-palette-teal);
$mf-primary-jump: mat.define-palette($mf-mat-palette-jump-blue);
$mf-accent-jump: mat.define-palette($mf-mat-palette-jump-blue);

$mf-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $mf-primary,
      accent: $mf-accent,
    ),
    typography:
      mat.define-typography-config(
        $font-family: 'Nunito Sans',
      ),
    density: 0,
  )
);

$mf-jump-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $mf-primary-jump,
      accent: $mf-accent-jump,
    ),
    typography:
      mat.define-typography-config(
        $font-family: 'Nunito Sans',
      ),
    density: 0,
  )
);

$mf-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $mf-primary,
      accent: $mf-accent,
    ),
    typography:
      mat.define-typography-config(
        $font-family: 'Nunito Sans',
      ),
    density: 0,
  )
);
