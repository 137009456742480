/*
 * Middlefork Color Palette
 */

/******************
 * Raw Colors
 *****************/
$mf-palette-blue: (
  30: #b6cfe8,
  40: #9dbfe0,
  50: #84aed8,
  60: #6c9ed1,
  70: #548ec9,
  80: #3b7ec1,
  90: #236eba,
  100: #0a5eb2,
);

$mf-palette-jump-blue-gray: (
  40: #394058,
  50: #2d3349,
  60: #222d49,
  70: #1a243d,
  80: #141f59,
);

$mf-palette-jump-blue: (
  30: #6db8ff,
  40: #51a8ff,
  50: #4099ff,
  60: #408af9,
  70: #3d78e5,
  80: #3d78e5,
  90: #3b65d2,
  100: #3646b2,
);

$mf-palette-jump-gray: (
  0: #ffffff,
  5: #a8a8a8,
  10: #70737a,
  20: #3a3e47,
  30: #343842,
  40: #2f323b,
  50: #252730,
  60: #1e202a,
  70: #191b24,
  80: #15161c,
  90: #101013,
  100: #000000,
);

$mf-palette-teal: (
  30: #b3e8ea,
  40: #99e0e3,
  50: #80d8dc,
  60: #66d1d6,
  70: #4dc9cf,
  80: #33c1c8,
  90: #1abac1,
  100: #00b2ba,
);

$mf-palette-gray: (
  0: #ffffff,
  5: #f2f2f2,
  10: #e6e6e6,
  20: #cccccc,
  30: #b3b3b3,
  40: #999999,
  50: #808080,
  60: #666666,
  70: #4d4d4d,
  80: #333333,
  90: #1a1a1a,
  100: #000000,
);

/******************
 * Semantic Color names
 *****************/
$mf-tokens-actions: (
  primary: map-get($mf-palette-blue, 100),
  secondary: map-get($mf-palette-teal, 100),
  disabled: map-get($mf-palette-gray, 40),
  focus: map-get($mf-palette-blue, 100),
);

$mf-tokens-feedback: (
  success: #1a892e,
  info: #008297,
  warning: #d38100,
  danger: #e61628,
);

$mf-tokens-jump-actions: (
  primary: #3b65d2,
  secondary: #8fabf4,
  disabled: #2448a7,
  focus: #365fc8,
);

$mf-tokens-jump-feedback: (
  success: #47a437,
  info: #008297,
  warning: #f8c543,
  danger: #df4d45,
);
