/* You can add global styles to this file, and also import other style files */

@use '@northfork/ag-grid/theme';
@use '@northfork/branding/styles';
@use '@northfork/branding/space';
@use '@northfork/branding/font';
@use '@northfork/branding/color';

@use '@angular/material' as mat;
@use 'mf-material-themes' as mf-mat-themes;
@use 'mf-theme-vars' as mf-vars;

@import '~@mdi/font/css/materialdesignicons.min.css';
@import '~ngx-toastr/toastr.css';

@include theme.northfork();

//set the light theme
@include mat.all-component-themes(mf-mat-themes.$mf-light-theme);

html,
body {
  height: 100%;
  overflow: hidden;
}

//you need to scope the variables INSIDE the ShadowDom
:root {
  //set light theme css vars
  @include mf-vars.mf-theme-light;
}

.env-badge {
  &.dev,
  &.local,
  &.staging,
  &.beta-user {
    font-size: 12px;
    margin-left: 10px;
    margin-bottom: -3px;
    padding: 2px 8px;
    border-radius: 5px;
  }

  &.dev,
  &.local {
    background-color: darken(color.alias(chart-color-10), 10%);
    color: white;
  }

  &.staging {
    background-color: color.alias(chart-color-4);
    color: white;
  }

  &.beta-user {
    background-color: color.alias(feedback-color-focus-default);
    color: white;
  }
}

.material-icons {
  font-family: 'Material Symbols Outlined';
}

.app-icon {
  width: 2rem !important;
  height: 2rem !important;
  margin-top: 5px;
}
