/*
 * Middlefork - theme variables for css
 * Usage:
 *
 * For applications, include the theme mixins at the root pseudoselector:
 * ```scss
 * :root {
 *    @include mf-vars.mf-theme-light;
 * }
 * ```
 *
 * For webcomponents, include the theme mixins under one of your highest-level containers (within the shadow dom)
 * ```scss
 * copilot-window {
 *    @include mf-vars.mf-theme-light;
 * }
 * ```
 */

@use 'mf-colors' as mf-colors;

@mixin mf-vars-actions {
  --mf-actions-primary: #{map-get(mf-colors.$mf-tokens-actions, primary)};
  --mf-actions-secondary: #{map-get(mf-colors.$mf-tokens-actions, secondary)};
  --mf-actions-disabled: #{map-get(mf-colors.$mf-tokens-actions, disabled)};
  --mf-actions-focus: #{map-get(mf-colors.$mf-tokens-actions, focus)};
}

@mixin mf-vars-feedback {
  --mf-feedback-success: #{map-get(mf-colors.$mf-tokens-feedback, success)};
  --mf-feedback-info: #{map-get(mf-colors.$mf-tokens-feedback, info)};
  --mf-feedback-warning: #{map-get(mf-colors.$mf-tokens-feedback, warning)};
  --mf-feedback-danger: #{map-get(mf-colors.$mf-tokens-feedback, danger)};
}

@mixin mf-vars-jump-actions {
  --mf-actions-primary: #{map-get(mf-colors.$mf-tokens-jump-actions, primary)};
  --mf-actions-secondary: #{map-get(
      mf-colors.$mf-tokens-jump-actions,
      secondary
    )};
  --mf-actions-disabled: #{map-get(mf-colors.$mf-tokens-jump-actions, disabled)};
  --mf-actions-focus: #{map-get(mf-colors.$mf-tokens-jump-actions, focus)};
}

@mixin mf-vars-jump-feedback {
  --mf-feedback-success: #{map-get(mf-colors.$mf-tokens-jump-feedback, success)};
  --mf-feedback-info: #{map-get(mf-colors.$mf-tokens-jump-feedback, info)};
  --mf-feedback-warning: #{map-get(mf-colors.$mf-tokens-jump-feedback, warning)};
  --mf-feedback-danger: #{map-get(mf-colors.$mf-tokens-jump-feedback, danger)};
}

@mixin mf-vars-background-dark {
  --mf-background-0: black;
  --mf-background-1: #{map-get(mf-colors.$mf-palette_gray, 90)};
  --mf-background-2: #{map-get(mf-colors.$mf-palette_gray, 70)};
  --mf-background-3: #{map-get(mf-colors.$mf-palette_gray, 50)};
  --mf-background-4: #{map-get(mf-colors.$mf-palette_gray, 30)};
  --mf-message-background-0: #{map-get(mf-colors.$mf-palette_gray, 80)}; // assistant
  --mf-message-background-1: #{map-get(mf-colors.$mf-palette_gray, 60)}; // user
  --mf-message-background-2: #{map-get(mf-colors.$mf-palette_gray, 40)}; //system
  --mf-font-color: white;
  --mf-font-color-reverse: black;
  --mf-font-color-caption: #{map-get(mf-colors.$mf-palette_gray, 30)};
}

@mixin mf-vars-background-jump-dark {
  --mf-background-0: #{map-get(mf-colors.$mf-palette_jump_gray, 100)};
  --mf-background-1: #{map-get(mf-colors.$mf-palette_jump_gray, 90)};
  --mf-background-2: #{map-get(mf-colors.$mf-palette_jump_gray, 70)};
  --mf-background-3: #{map-get(mf-colors.$mf-palette_jump_gray, 50)};
  --mf-background-4: #{map-get(mf-colors.$mf-palette_jump_gray, 30)};
  --mf-background-5: #{map-get(mf-colors.$mf-palette_jump_gray, 20)};
  --mf-background-6: #{map-get(mf-colors.$mf-palette_jump_gray, 10)};
  --mf-message-background-0: #{map-get(mf-colors.$mf-palette_jump_gray, 30)}; // assistant
  --mf-message-background-1: #{map-get(mf-colors.$mf-palette_jump_gray, 10)}; // user
  --mf-message-background-2: #{map-get(mf-colors.$mf-palette_jump_blue_gray, 50)}; //system
  --mf-font-color: white;
  --mf-font-color-reverse: black;
  --mf-font-color-caption: #{map-get(mf-colors.$mf-palette_jump_gray, 30)};
}

@mixin mf-vars-background-light {
  --mf-background-0: white;
  --mf-background-1: #{map-get(mf-colors.$mf-palette_gray, 5)};
  --mf-background-2: #{map-get(mf-colors.$mf-palette_gray, 10)};
  --mf-background-3: #{map-get(mf-colors.$mf-palette_gray, 20)};
  --mf-background-4: #{map-get(mf-colors.$mf-palette_gray, 40)};
  --mf-message-background-0: white; // assistant
  --mf-message-background-1: #{map-get(mf-colors.$mf-palette_gray, 10)}; // user
  --mf-message-background-2: #{map-get(mf-colors.$mf-palette_gray, 20)}; // system
  --mf-font-color: black;
  --mf-font-color-reverse: white;
  --mf-font-color-caption: #{map-get(mf-colors.$mf-palette_gray, 40)};
}

/**************
 * THEME MIXINS
 *************/
@mixin mf-theme-light {
  @include mf-vars-actions;
  @include mf-vars-feedback;
  @include mf-vars-background-light;
}

@mixin mf-theme-dark {
  @include mf-vars-actions;
  @include mf-vars-feedback;
  @include mf-vars-background-dark;
}

@mixin mf-theme-jump-dark {
  @include mf-vars-jump-actions;
  @include mf-vars-jump-feedback;
  @include mf-vars-background-jump-dark;
}
